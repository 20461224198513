import { SVGAttributes, forwardRef } from 'react';

export type Props = SVGAttributes<SVGElement>;

export const Flippingbook = forwardRef<SVGSVGElement, Props>(
  ({ fill = 'currentColor', ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        viewBox="0 0 22.5 21.7"
        fill={fill}
        {...props}
        ref={ref}
      >
        <path d="M19.8,16.2h0v1.8c0,.3-.3.6-.6.6,0,0-.1,0-.2,0l-5.8-3c.3-.7.5-1.5.5-2.2,0-.9-.3-1.7-.8-2.5l-.9-1.6h0c-.2-.3-.3-.7-.3-1,0-.8.4-1.5,1-1.8l6.4-3.3c0,0,.1,0,.2,0,.3,0,.6.2.6.6v1.8h0s0,10.8,0,10.8ZM9.9,15.3l-6.4,3.3c0,0-.1,0-.2,0-.3,0-.6-.2-.6-.6V3.7c0-.3.3-.6.6-.6,0,0,.1,0,.2,0l5.8,3h0s0,0,0,0c-.3.7-.5,1.5-.5,2.2,0,.9.3,1.7.8,2.5l.9,1.6h0c.2.3.3.6.3,1,0,.8-.4,1.5-1,1.8ZM19.8,0c-.4,0-.8,0-1.2.2l-7.1,3.6c0,0-.2.1-.3.2h0s0,0,0,0h0L3.9.3C3.5.1,3.1,0,2.7,0,1.4,0,.3,1,0,2.2c0,.1,0,.2,0,.4v16.5c0,0,0,.1,0,.2.1,1.4,1.3,2.4,2.7,2.5.4,0,.8,0,1.2-.2l7.1-3.6c0,0,.2-.1.3-.2l7.4,3.8c.4.2.8.2,1.2.2,1.3,0,2.4-.9,2.7-2.2,0-.1,0-.2,0-.4V2.6c0,0,0-.1,0-.2C22.4,1.1,21.2,0,19.8,0ZM11.3,9.5h0s0,0,0,0ZM10.4,13h0s0,0,0,0Z" />
      </svg>
    );
  }
);

Flippingbook.displayName = 'Flippingbook';

export default Flippingbook;
